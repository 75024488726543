<template>
  <v-dialog
    v-model="dialogNovoEditCargo"
    @click:outside="$emit('update:dialogNovoEditCargo', false)"
    @keydown.esc="$emit('update:dialogNovoEditCargo', false)"
    max-width="1200"
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        {{ adicionar ? "Adicionar" : "Editar" }} Cargo
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogNovoEditCargo', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-row>
          <!-- Descrição -->
          <v-col cols="12" :md="!adicionar ? '8' : '12'">
            <v-text-field
              v-model="cargo.descricao"
              label="Descricao"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Status -->
          <v-col v-if="!adicionar" cols="12" md="4">
            <v-text-field dense readonly value="Status:">
              <template v-slot:append>
                <v-switch
                  v-model="cargo.status"
                  :false-value="0"
                  :true-value="1"
                  class="mt-0 pt-0"
                  hide-details
                >
                </v-switch>
              </template>
            </v-text-field>
          </v-col>

          <!-- Jornada de Trabalho -->
          <v-col cols="12" class="">
            <div class="d-flex align-center justify-space-between">
              <div class="text-body-1">Jornada de Trabalho</div>
              <v-btn
                v-if="!jornadas_trabalho.length"
                small
                depressed
                class="mb-2"
                @click="jornadas_trabalho = jornadas_trabalho_base"
              >
                CADASTRAR
              </v-btn>
            </div>
            <v-divider class=""></v-divider>
            <v-container class="mt-3 px-0">
              <v-row
                v-for="(jornada, index) in jornadas_trabalho"
                :key="index"
                justify="center"
              >
                <!-- Dia Semana -->
                <v-col>
                  <v-text-field
                    v-model="jornada.dia_semana"
                    label="Dia da Semana"
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- Entrada -->
                <v-col>
                  <v-text-field
                    v-model="jornada.entrada"
                    label="Entrada"
                    type="time"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Saida Intervalo -->
                <v-col>
                  <v-text-field
                    v-model="jornada.saida_intervalo"
                    label="Saida Intervalo"
                    type="time"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Volta Intervalo -->
                <v-col>
                  <v-text-field
                    v-model="jornada.volta_intervalo"
                    label="Volta Intervalo"
                    type="time"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Saída -->
                <v-col>
                  <v-text-field
                    v-model="jornada.saida"
                    label="Saída"
                    type="time"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="px-6 py-4">
        <v-btn @click="$emit('update:dialogNovoEditCargo', false)">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="adicionar ? createCargo() : updateCargo()"
          :disabled="disableAddEdit"
          color="secondary"
          class="white--text"
        >
          {{ adicionar ? "Adicionar" : "Atualizar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchCargo, postCargo, putCargo } from "@/api/gestor/cargos.js";

export default {
  name: "NovoEditCargo",

  props: {
    dialogNovoEditCargo: {
      type: Boolean,
      required: true,
    },
    adicionar: {
      type: Boolean,
      required: true,
    },
    cargo_id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      loading: false,
      cargo: {
        descricao: null,
      },
      jornadas_trabalho: [],
      jornadas_trabalho_base: [
        {
          id: null,
          ordem: 1,
          dia_semana: "DOMINGO",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
        {
          id: null,
          ordem: 2,
          dia_semana: "SEGUNDA",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
        {
          id: null,
          ordem: 3,
          dia_semana: "TERÇA",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
        {
          id: null,
          ordem: 4,
          dia_semana: "QUARTA",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
        {
          id: null,
          ordem: 5,
          dia_semana: "QUINTA",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
        {
          id: null,
          ordem: 6,
          dia_semana: "SEXTA",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
        {
          id: null,
          ordem: 7,
          dia_semana: "SÁBADO",
          entrada: "00:00",
          saida_intervalo: "00:00",
          volta_intervalo: "00:00",
          saida: "00:00",
        },
      ],
    };
  },

  computed: {
    disableAddEdit() {
      let result = false;

      if (!this.cargo.descricao) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    async getCargo() {
      try {
        const response = await fetchCargo(this.cargo_id);
        this.cargo = response;
        if (response.jornadas_trabalho.length) {
          this.jornadas_trabalho = response.jornadas_trabalho;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createCargo() {
      try {
        this.loading = true;

        this.cargo.jornadas_trabalho = this.jornadas_trabalho;

        const response = await postCargo(this.cargo);
        if (response.status === 201) {
          this.$toast.success("Cargo criado com sucesso!");
          this.$emit("fetch-cargos");
          this.$emit("update:dialogNovoEditCargo", false);
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async updateCargo() {
      try {
        this.loading = true;

        this.cargo.jornadas_trabalho = this.jornadas_trabalho;

        const response = await putCargo(this.cargo.id, this.cargo);
        if (response.status === 201) {
          this.$toast.success("Cargo atualizado com sucesso!");
          this.$emit("fetch-cargos");
          this.$emit("update:dialogNovoEditCargo", false);
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.loading = true;
    if (this.cargo_id && !this.adicionar) {
      await this.getCargo();
    }
    this.loading = false;
  },
};
</script>

<style lang="scss"></style>
